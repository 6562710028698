import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import { Helmet } from "react-helmet"

const ArticleTemplate = ({ data }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.strapiArticle.title} | Mighty Lab Blog</title>
    </Helmet>

    <h1>{data.strapiArticle.title}</h1>
    <p>
      by{" "}
      <Link to={`/authors/${data.strapiArticle.author.slug}`}>
        {data.strapiArticle.author.username}
      </Link>
    </p>
    <Img fluid={data.strapiArticle.image.childImageSharp.fluid} />
    <ReactMarkdown
      source={data.strapiArticle.content}
      transformImageUri={uri =>
        uri.startsWith("http") ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
      }
      className="articleContent"
      escapeHtml={false}
    />
  </>
)

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      title
      content
      image {
        childImageSharp {
          fluid(maxWidth: 2200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      author {
        id
        slug
        username
      }
    }
  }
`
